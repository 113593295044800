<template>
  <b-row>
      <b-overlay :show="loading">
          <div class="px-3">
              <b-row>
                  <b-col lg="12" xs="12" md="12">
                    <h5 class="text-center">{{ $i18n.locale === 'en' ? 'Form-3 (Annual)' : 'ফরম-৩ (বার্ষিক)' }}</h5>
                  </b-col>
                  <b-col lg="12" xs="12" md="12" class="mb-3 mt-1">
                    <list-report-head :base-url="baseUrl" uri="/configuration/report-heading/detail" :org-id="5">
                      <template v-slot:projectNameSlot>
                      </template>
                      {{ $i18n.locale === 'en' ? 'Return on Employment & Employment Cost' : 'শ্রম ও মজুরী সংক্রান্ত রিটার্ণ' }}
                    </list-report-head>
                  </b-col>
              </b-row>
              <b-row v-if="detailsData">
                  <b-col sm="6">
                      <div>
                        <span><b>{{ $i18n.locale === 'en' ? 'Name of the Tea Garden/Factory' : 'বাগান/কারখানার নাম' }}: </b></span>
                        <span>{{ detailsData.return_type == 1 ? getGardenName(detailsData.garden_fact_id) : getFactName(detailsData.garden_fact_id) }}</span>
                      </div>
                  </b-col>
                  <b-col sm="6">
                      <div class="text-right">
                          {{ $i18n.locale === 'en' ? '(For the year ending 31-12-' + detailsData.year + ')' :  '( ৩১-১২-' + $n(detailsData.year, { useGrouping: false }) + ' এ সমাপ্ত বৎসরের জন্য)' }}
                      </div>
                  </b-col>
                  <b-col sm="12" class="mt-2">
                        <template>
                          <h6 style="background: #B6D0E2 " class="card-title text-sm-center py-1">{{ $t('teaGarden.staff_info') }}</h6>
                        </template>
                        <b-table-simple bordered>
                          <thead>
                            <tr class="bg-primary">
                              <th class="text-center" style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                              <th class="text-center" style="width:20%">{{ $t('teaGarden.employees') }}</th>
                              <th class="text-center" style="width:7%">{{ $t('teaGarden.ban_emp') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.foreign_emp') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.surplus_person') }}</th>
                              <th class="text-center" style="width:7%">{{ $t('teaGarden.dificit') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.dependents') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.total_population') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.avg_salary') }}</th>
                            </tr>
                          </thead>
                          <b-tbody>
                            <b-tr v-for="(item, index) in detailsData.details?.filter(el => parseInt(el.emp_type) === 1)" :key="index">
                              <b-td class="text-center">
                                  {{ $n(index + 1) }}
                              </b-td>
                              <b-td>
                                <span v-if="item.emp_type === 1">{{ getEmployeeList(item.emp_lab_id) }}</span>
                                <span v-if="item.emp_type === 2">{{ getLabourerList(item.emp_lab_id) }}</span>
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.ban_emp) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.foreign_emp) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.surplus_person) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.dificit) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.dependents) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.total_population) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.avg_salary, { minimumFractionDigits: 2 }) }} <span v-if="item.emp_type === 1">{{ $i18n.locale == 'bn' ? "(মাসিক)" : "(Monthly)" }}</span><span v-if="item.emp_type === 2">{{ $i18n.locale == 'bn' ? "(দৈনিক)" : "(Daily)" }}</span>
                              </b-td>
                          </b-tr>
                          </b-tbody>
                        </b-table-simple>
                        <template>
                          <h6 style="background: #B6D0E2 " class="card-title text-sm-center py-1">{{ $t('teaGarden.labourer_info') }}</h6>
                        </template>
                        <b-table-simple bordered>
                          <thead>
                            <tr class="bg-primary">
                              <th class="text-center" style="width:5%">{{ $t('globalTrans.sl_no') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.labourers') }}</th>
                              <th class="text-center" style="width:7%">{{ $t('teaGarden.ban_emp') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.foreign_emp') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.surplus_person') }}</th>
                              <th class="text-center" style="width:7%">{{ $t('teaGarden.dificit') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.dependents') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.total_population') }}</th>
                              <th class="text-center" style="width:10%">{{ $t('teaGarden.avg_salary') }}</th>
                            </tr>
                          </thead>
                          <b-tbody>
                            <b-tr v-for="(item, index) in detailsData.details?.filter(el => parseInt(el.emp_type) === 2)" :key="index">
                              <b-td class="text-center">
                                  {{ $n(index + 1) }}
                              </b-td>
                              <b-td>
                                <span v-if="item.emp_type === 1">{{ getEmployeeList(item.emp_lab_id) }}</span>
                                <span v-if="item.emp_type === 2">{{ getLabourerList(item.emp_lab_id) }}</span>
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.ban_emp) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.foreign_emp) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.surplus_person) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.dificit) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.dependents) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.total_population) }}
                              </b-td>
                              <b-td class="text-center">
                                {{ $n(item.avg_salary, { minimumFractionDigits: 2 }) }} <span v-if="item.emp_type === 1">{{ $i18n.locale == 'bn' ? "(মাসিক)" : "(Monthly)" }}</span><span v-if="item.emp_type === 2">{{ $i18n.locale == 'bn' ? "(দৈনিক)" : "(Daily)" }}</span>
                              </b-td>
                          </b-tr>
                          </b-tbody>
                        </b-table-simple>
                  </b-col>
                  <b-col md="12" class="text-left">
                      <span><strong>{{ $t('globalTrans.date') }}: </strong></span>
                      <span>{{ detailsData.date | dateFormat }}</span>
                  </b-col>
              </b-row>
          </div>
      </b-overlay>
  </b-row>
</template>
<script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { returnThreeShowApi } from '../../api/routes'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  // import Pdf from './details-pdf'
  export default {
      name: 'Details',
      props: ['id'],
      components: { ListReportHead },
      data () {
          return {
              baseUrl: teaGardenServiceBaseUrl,
              detailsData: {}
          }
      },
      created () {
        if (this.id) {
          this.formData()
        }
      },
      computed: {
          currentLocale () {
              return this.$i18n.locale
          },
          loading () {
              return this.$store.state.commonObj.loading
          }
      },
      methods: {
        getGardenName (id) {
          const objectData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
          if (typeof objectData !== 'undefined') {
              return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            } else {
              return ''
            }
        },
        getFactName (id) {
          const objectData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === id)
          if (typeof objectData !== 'undefined') {
              return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            } else {
              return ''
            }
        },
        getReturnType (id) {
          const objectData = this.$store.state.TeaGardenService.commonObj.returnTypeList.find(item => item.value === id)
          if (typeof objectData !== 'undefined') {
              return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            } else {
              return ''
            }
        },
        getStaffTypeList (id) {
          const objectData = this.$store.state.TeaGardenService.commonObj.staffTypeList.find(item => item.value === id)
          if (typeof objectData !== 'undefined') {
              return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            } else {
              return ''
            }
        },
        getEmployeeList (id) {
          const objectData = this.$store.state.TeaGardenService.commonObj.employeeList.find(item => item.value === id)
          if (typeof objectData !== 'undefined') {
              return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            } else {
              return ''
            }
        },
        getLabourerList (id) {
          const objectData = this.$store.state.TeaGardenService.commonObj.labourerList.find(item => item.value === id)
          if (typeof objectData !== 'undefined') {
              return this.$i18n.locale === 'en' ? objectData.text_en : objectData.text_bn
            } else {
              return ''
            }
        },
        async formData () {
            // this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, returnThreeShowApi + '/' + this.id)
            if (result.success) {
                this.detailsData = result.data
            }
            // this.loading = false
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        },
        async pdfExport () {
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
            const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 24)
            if (service !== undefined) {
              if (service.office_type_id) {
                params.office_type_id = service.office_type_id
              }
              if (service.office_id) {
                params.office_id = service.office_id
              }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, returnThreeShowApi + '/' + this.id, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      }
  }
</script>
<style scoped>
  .tag {
      font-weight: 600;
      border-left: 4px solid #1c4261;
      padding-left: 6px;
  }
  .download-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 2px 4px;
  }
  .yes-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
  }
  .no-btn {
      color: red;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
      margin-right: 33px !important;
  }
  .tag {
      font-weight: 600;
      border-left: 4px solid #1c4261;
      padding-left: 6px;
  }
  .download-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 2px 4px;
  }
  .yes-btn {
      color: green;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
  }
  .no-btn {
      color: red;
      border: 1px solid gray;
      margin-left: 5px;
      font-size: 18px;
      padding: 1px 3px;
      margin-right: 33px !important;
  }

  .stepper-wrapper {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
  }
  .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
  .stepper-item::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 20px;
      left: -50%;
      z-index: 2;
  }

  .stepper-item::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 20px;
      left: 50%;
      z-index: 2;
  }

  .stepper-item .step-counter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ccc;
      margin-bottom: 6px;
      color: white;
  }

  .stepper-item.active {
      font-weight: bold;
  }

  .stepper-item.completed .step-counter {
      background-color: #4bb543;
      color:white;
  }

  .stepper-item.completed::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid #4bb543;
      width: 100%;
      top: 20px;
      left: 50%;
      z-index: 3;
  }

  .stepper-item:first-child::before {
      content: none;
  }
  .stepper-item:last-child::after {
      content: none;
  }
  .step-name {
      color: black;
      font-weight: bold;
      font-size: 11px;
  }
  .tagTwo {
      position: relative;
      display: inline-block;
      border-radius: 6px;
      clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
      background: hsl(250deg, 100%, 40%);
      padding: 5px 25px;
      font-weight: 600;
      font-size: 12px;
      color: #FFF;
      transition: clip-path 500ms;
  }
  .tagTwo:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: hsl(252deg, 100%, 60%);
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 6px 0;
      transition: transform 500ms;
  }
</style>
